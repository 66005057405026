<template>
	<div id="meetList">
		<div class="top">
			<div class="title">项目：{{projectNmae}}</div>
			<div class="kong">
				<div class="item i1" @click="creatorMeet()">
					<img src="../../assets/imgs/kong1.png" alt="">
					<span>创建会议</span>
				</div>
				<div class="item i2" @click="sectionChange({ class: 'UPLOAD' })">
					<img src="../../assets/imgs/kong2.png" alt="">
					<span>待会后上传</span>
					<i class="redDot" v-if="upNum&&upNum>0">{{upNum}}</i>
				</div>
				<div class="item i3" @click="goDoctorList()">
					<img src="../../assets/imgs/kong3.png" alt="">
					<span>项目医生</span>
				</div>
			</div>
		</div>
		<!-- 项目列表 -->
		<div class="hzCont">
			<div class="list">
				<h1 v-if="todayMeetList[0]">今日会议</h1>
				<el-carousel height="350px" v-if="todayMeetList[0]" indicator-position="inside" :autoplay="false">
					<el-carousel-item v-for="item in todayMeetList" :key="item.id">
						<div class="bgImg">
							<img v-if="item.bgImage" :src="item.bgImage">
							<img v-else src="../../assets/imgs/back2.png">
							<span></span>
						</div>
						<div class="main">
							<div class="tit">
								会议主题：
								{{item.meetingTitle}}
							</div>
							<div class="pro">
								<img src="../../assets/imgs/icon1.png">
								所属项目：
								{{item.projectName}}
							</div>
							<div class="time">
								<img src="../../assets/imgs/icon2.png">
								开始时间：
								{{$moment(item.meetingStartTime).format('L') }}
								{{$moment(item.meetingStartTime).format('LT') }}
							</div>
							<div class="users">
								<img src="../../assets/imgs/icon3.png">
								参会医生：
								{{item.doctors}}
							</div>
							<el-button class="goLive" @click="enterTheMeeting(item)">进入会议</el-button>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="tabs">
					<template v-for="(item,i) in list">
						<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
							{{item.name}}
							<span v-if="item.class == 'WAIT'">{{recentTotal}}</span>
							<i></i>
						</p>
						<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
					</template>
				</div>
				<h1>会议列表</h1>
				<div class="item" v-for="item in meetingList" :key="item.id" @click="toMeetingDetails(item)">
					<div class="tags">
						<div class="type" v-if="item.meetingType == '患教会'">
							<img :src="require('../../assets/imgs/video.png')">患教会
						</div>
						<div class="type" v-if="item.meetingType == '研讨会'">
							<img :src="require('../../assets/imgs/video2.png')">研讨会
						</div>
						<div class="idNum">编号：{{item.id}}</div>
					</div>
					<div class="main">
						<div class="left">
							<div class="tit">会议主题：{{item.meetingTitle}}</div>
							<div class="time">
								会议时间：
								{{ $moment(item.meetingStartTime).format('YYYY-MM-DD') }}
								{{ $moment(item.meetingStartTime).format('LT') }}
							</div>
							<div class="type">
								<span v-if="item.meetingStatus == 'WAIT'" class="type WAIT">待开始</span>
								<span v-if="item.meetingStatus == 'LIVING'" class="type LIVING">进行中</span>
								<span v-if="item.meetingStatus == 'FINISH'" class="type FINISH">已结束</span>
								<span v-if="item.meetingStatus == 'UPLOAD'" class="type UPLOAD">待上传证明</span>
							</div>
						</div>
						<div class="opts">
							<el-button @click.stop="toMeetingDetails(item)" type="primary">会议详情</el-button>
							<el-button v-if="item.meetingStatus == 'WAIT' || item.meetingStatus=='LIVING'"
								type="primary" @click.stop="enterTheMeeting(item)">进入会议</el-button>
							<el-button v-if="item.meetingStatus == 'UPLOAD'" type="warning"
								@click.stop="uploadContent(item)">会后上传</el-button>
							<el-button v-if="item.meetingStatus == 'ERROR'" type="danger"
								@click.stop="uploadContent(item)">重新上传</el-button>
						</div>
					</div>
				</div>
				<div class="null" v-if="!meetingList[0]">
					<img src="../../assets/imgs/EmptyState.png" alt="">
					<p>暂时没有会议内容</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" class="pagination" @current-change="getMyProject"
				:hide-on-single-page="false"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		todayMeeting,
		getMeetingUrl,
		uploadMeetingNum,
		meetList
	} from '@/request/api.js';
	export default {
		name: 'meetList',
		components: {},
		data() {
			return {
				projectId: '',
				projectNmae: '',
				curNow: "WAIT",
				upNum: 0,
				page: 1,
				size: 5,
				recentTotal: 0,
				total: 0,
				meetingList: [],
				todayMeetList: [],
				list: [{
					name: '近期会议',
					class: 'WAIT',
				}, {
					name: '已结束会议',
					class: 'FINISH',
				}],
				message: ''
			}
		},
		created() {
			this.projectId = this.$route.query.pId;
			this.getTodayMeeting();
			this.getUploadMeetingNum();
			this.getMeetingList();
		},
		mounted() {},
		methods: {
			// 查看会议详情
			toMeetingDetails(item) {
				this.$router.push({
					name: 'meetdetails',
					query: {
						mId: item.id,
						status: item.meetingStatus,
						now: '3'
					}
				})
			},
			uploadContent(item) {
				console.log(item)
				this.$router.push({
					name: 'meetdetails',
					query: {
						mId: item.id,
						status: item.meetingStatus,
						now: '4'
					}
				})
			},

			goDoctorList() {
				this.$router.push({
					name: 'doctor',
				})
			},

			// 创建会议
			creatorMeet() {
				this.$router.push({
					name: 'createmeet',
					query: {
						pId: this.projectId
					}
				})
			},

			// 获取会议列表
			getMeetingList() {
				let data = {
					projectId: this.projectId,
					size: this.size,
					current: this.page,
					meetingType: this.curNow
				};
				// if (this.curNow === '1') data.meetingType = 'WAIT';
				// if (this.curNow === '2') data.meetingType = 'FINISH';
				// if (this.curNow === '3') data.meetingType = 'UPLOAD';
				meetList(data).then(res => {
					if (res.code == 200) {
						this.meetingList = res.data.records;
						this.total = res.data.total;
						if (data.meetingType == "WAIT") this.recentTotal = res.data.total;
						if (this.meetingList[0]) this.projectNmae = this.meetingList[0].projectName;
					}
				})
			},

			// 查询会后待上传数量
			getUploadMeetingNum() {
				uploadMeetingNum({
					projectId: this.projectId
				}).then(res => {
					if (res.code == 200) {
						this.upNum = res.data
					}
				})

			},

			// 进入会议
			enterTheMeeting(item) {
				let data = {
					meetingId: item.id
				}
				getMeetingUrl(data).then(res => {
					if (res.code == 200) {
						// console.log(res)
						window.open(res.data)
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
						this.$notify({
							type: 'error',
							title: '错误',
							message: res.msg,
							position: 'bottom-right'
						});
					}
				})
			},

			// 获取今日会议列表
			getTodayMeeting() {
				todayMeeting({
					projectId: this.projectId
				}).then(res => {
					if (res.code == 200) {
						let data = res.data;
						data.map(item => {
							item.doctors = item.doctors.split(',').join("，");
							return item;
						})

						this.todayMeetList = data;
						// this.$forceUpdate();
					}
				})
			},

			// 分页切换
			getMyProject(e) {
				// console.log(e)
				this.page = e;
				this.getMeetingList();
			},

			// 切换会诊选项
			tabsChange(item) {
				this.curNow = item.class;
				this.page = 1;
				this.getMeetingList();
			},

			sectionChange(item) {
				this.curNow = item.class;
				this.page = 1;
				this.getMeetingList();
				this.curNow = 'FINISH';
			}
		}
	}
</script>

<style lang="scss" scoped>
	#meetList {
		display: flex;
		flex-direction: column;

		.top {
			margin: 20px 20px 0;
			padding: 20px 20px 0;
			box-shadow: 0 2px 15px rgba(203, 203, 203, 0.31);
			border-radius: 20px;

			.title {
				font-size: 20px;
				margin-bottom: 20px;
			}

			.kong {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
				margin-bottom: 20px;

				.item {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					position: relative;
					padding: 10px;
					border-radius: 50px;

					.redDot {
						top: 0;
						right: 0;
						color: #fff;
						width: 26px;
						height: 26px;
						display: flex;
						font-size: 14px;
						border-radius: 50%;
						font-style: normal;
						position: absolute;
						border: 3px solid #fff;
						background-color: #D43030;
						justify-content: center;
						align-items: center;
						font-weight: bold;
					}

					* {
						cursor: pointer;
					}

					img {
						width: 60px;
						height: 60px;
					}

					span {
						width: 240px;
						font-size: 18px;
						text-align: center;
						margin-right: 30px;
					}
				}

				.i1 {
					background-color: #D5E5F7;
				}

				.i2 {
					background-color: #FFF4DB;
				}

				.i3 {
					background-color: #F3FFDB;
				}
			}

		}

		.hzCont {
			flex: 1;
			padding: 20px;
			display: flex;
			flex-direction: column;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;


			.tabs {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;
				margin-bottom: 20px;

				.el-divider {
					width: 2px;
					height: 30px;
					margin: 0;
				}

				p {
					width: 100%;
					padding: 20px 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					cursor: pointer;
					line-height: 2.5;
					position: relative;

					i {
						width: 100%;
						left: 0;
						bottom: 0;
						height: 0;
						background-color: #0065FF;
						position: absolute;
						transition: all .3s;
					}

					span {
						margin-left: 10px;
						height: 26px;
						width: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						background-color: #007AFF;
						border-radius: 5px;
						color: #fff;
					}
				}

				.active i {
					height: 6px;
				}
			}

			.list {
				flex: 1;

				h1 {
					color: #000;
					font-size: 20px;
					margin-bottom: 20px;
				}

				.itemToday {
					display: flex;
					margin-bottom: 20px;
					flex-direction: row;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;
					align-items: center;

					&:hover {
						background-color: #E8EEFC;
					}

					.main {
						flex: 1;
						display: flex;
						flex-direction: column;

						.tag {
							display: flex;
							margin-bottom: 10px;

							span {
								height: 20px;
								background-color: #007AFF;
								font-size: 12px;
								border-radius: 5px;
								color: #fff;
								display: flex;
								align-items: center;
								padding: 0 10px;
							}
						}

						.tit {
							font-size: 16px;
							color: #000;
							margin-bottom: 10px;
							line-height: 1;
						}

						.time {
							font-size: 13px;
							color: #007AFF;
							line-height: 1;
						}
					}

					.goLive {
						background: #0065FF;
						color: #fff;
						padding: 0 25px;
						height: 28px;
					}
				}

				.el-carousel {
					margin-bottom: 10px;
				}

				.el-carousel__item {
					display: flex;
					flex-direction: row;

					.bgImg {
						flex: 1;
						height: 100%;
						overflow: hidden;
						object-fit: cover;

						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
							border-radius: 20px;
						}
					}

					.main {
						flex: 1;
						padding: 20px 0 20px 40px;
						display: flex;
						flex-direction: column;
						align-items: space-between;
						justify-content: space-between;

						>div {
							font-size: 16px;
							display: flex;
							align-items: center;
							color: #112950;

							img {
								height: 16px;
								margin-right: 5px;
							}
						}

						.tit {
							font-size: 22px;
							color: #000;
							word-wrap: break-word;
							white-space: normal;
							word-break: break-all;
							// overflow: hidden;
							// white-space: nowrap;
							// text-overflow: ellipsis;
						}

						.goLive {
							color: #fff;
							height: 50px;
							background-color: #D43030;
						}
					}
				}

				.item {
					display: flex;
					margin-bottom: 20px;
					flex-direction: column;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;

					&:hover {
						background-color: #E8EEFC;
					}

					.tags {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 10px;

						.type {
							display: flex;
							align-items: center;
							color: #000;
							font-size: 12px;

							img {
								margin-right: 5px;
								width: 20px;
								height: 15px;
							}
						}

						.idNum {
							font-size: 12px;
							color: #999;
						}
					}



					.main {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.left {
							.tit {
								font-size: 16px;
								color: #000;
								margin-bottom: 10px;
								line-height: 1;
							}

							.time {
								font-size: 16px;
								color: #333;
								line-height: 1;
								margin-bottom: 10px;
							}

							.type {
								display: flex;

								.el-button--text {
									padding: 0;
									font-size: 13px;
								}

								.WAIT {
									color: #007AFF;
								}

								.FINISH {
									color: #999;
								}
							}
						}

						.opts {
							display: flex;
							align-items: flex-end;

							.el-button {
								padding: 0 25px;
								height: 28px;
							}

							.el-button--primary {
								background-color: #0065ff;
							}

							.el-button--warning {
								background-color: #E6A23C;
							}

							.el-button--danger {
								background-color: #F56C6C;
							}

							.agreement {
								background-color: #D43030;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}
						}
					}
				}

				.null {
					width: 100%;
					padding: 100px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
					}
				}
			}

			.el-pagination {
				margin: auto;
			}
		}

	}
</style>